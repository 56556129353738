import { parseTemplate, type PrimitiveValue } from "url-template";

// https://datatracker.ietf.org/doc/html/rfc6570

type OpenBrace = "{";
type CloseBrace = "}";
type Separator = ",";
type Expansion = "*";
type ParameterType = "?" | "&" | "#" | "." | ";" | "/" | "+";
type IllegalNameChar = Exclude<
  OpenBrace | CloseBrace | Separator | Expansion | ParameterType,
  "."
>;

/**
 * Removes any `ParameterType` from the front of a string and
 * any `Expansion` from the end.
 */
type RemoveModifiers<T extends string> =
  T extends `${ParameterType}${infer Name}${Expansion}`
    ? Name
    : T extends `${ParameterType}${infer Name}`
      ? Name
      : T extends `${infer Name}${Expansion}`
        ? Name
        : T;

/**
 * Matches legal parameter names with optional modifiers.
 */
type ParameterName<T extends string> =
  RemoveModifiers<T> extends `${string}${IllegalNameChar}${string}`
    ? never
    : RemoveModifiers<T>;

/**
 * Matches a list of comma separated parameters.
 *
 * e.g. "?one,two*"  => "one" | "two"
 */
type ParameterList<T extends string> =
  T extends `${infer P}${Separator}${infer Rest}`
    ? ParameterName<P> extends infer Name extends string
      ? Name | ParameterList<Rest>
      : never
    : ParameterName<T> extends infer Name extends string
      ? Name
      : never;

/**
 * Matches all parameter groups
 *
 * e.g. "/path/{one}/path/{?two*,three}" => "one" | "two" | "three"
 */
type TemplateParameters<
  T extends string,
  Params extends string = never,
> = T extends `${string}${OpenBrace}${infer List}${CloseBrace}${infer Rest}`
  ? TemplateParameters<Rest, ParameterList<List> | Params>
  : Params;

/**
 * Transforms /{id} syntax to /:id syntax used by $api
 *
 * e.g. "/path/{one}/path/{two}" => "/path/:one/path/:two"
 */
type ApiFormatInner<T extends string> =
  T extends `${infer Before}${OpenBrace}${infer List}${CloseBrace}${infer Rest}`
    ? `${Before}:${List}${ApiFormatInner<Rest>}`
    : T;
type ApiFormat<T extends string> = ApiFormatInner<T>;

export type ExpandValue =
  | PrimitiveValue
  | PrimitiveValue[]
  | Record<string, PrimitiveValue>;
type Expand<T extends string> = [T] extends [never]
  ? never
  : { [K in T]?: ExpandValue };

export type Template<T extends string> =
  TemplateParameters<T> extends infer Params extends string
    ? [Params] extends [never]
      ? {
          expand(): ApiFormat<T>;
        }
      : {
          expand(parameters: Expand<Params>): ApiFormat<T>;
        }
    : never;

export function parseUriTemplate<T extends string>(input: T): Template<T> {
  return parseTemplate(input) as Template<T>;
}
